
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import CryptoJS from "crypto-js";

export default defineComponent({
  name: "MapComparison-Detail",
  props: ["id", "year"],
  setup(props) {
    setCurrentPageTitle("Komparasi Peta Suara");
    const store = useStore();
    const router = useRouter();

    store.commit("SET_ACTIVE_MENU", "map-comparison");

    const dataId = CryptoJS.AES.decrypt(props.id, "PKS.id").toString(
      CryptoJS.enc.Utf8
    );
    const dataYear = CryptoJS.AES.decrypt(props.year, "PKS.id").toString(
      CryptoJS.enc.Utf8
    );
    if (!dataId && !dataYear) {
      router.push({ name: "comparison.map" });
    }

    const backMenu = () => {
      router.push({ name: "comparison.map" });
    };

    return {
      backMenu,
      dataId,
      dataYear,
    };
  },
});
